import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby';

const BackToBlock = styled.div`
  margin-bottom:20px;
`;

const ChevronIcon = styled.div`
  z-index:15;
  height:20px;
  >svg{
    height:100%;
    width:100%;
    overflow: visible;
    >path{
      fill: black;
    }
  }
`
const BackLink = styled(Link)`
  display:flex;
  align-items:center;
  font-family:"Poppins";
  font-size:1rem;
  color:${props => props.theme.darkGrey};
  text-transform:uppercase;
`;

function BreadCrumbs() {
  return (
    <BackToBlock>
      <BackLink to={'/blog'}>
        <ChevronIcon>
          <svg viewBox="0 0 50 50" width="40px" height="40px"><path d="M0.6,24.71L17.32,7.99c0.81-0.81,2.11-0.81,2.92,0l1.95,1.95 c0.8,0.81,0.8,2.11,0,2.92L8.95,26.17L22.2,39.48c0.8,0.81,0.8,2.11,0,2.92l-1.95,1.95c-0.81,0.81-2.11,0.81-2.92,0L0.6,27.63 C-0.2,26.82-0.2,25.51,0.6,24.71z" class="BackButton__ChevronPath-sc-16uzy26-0 bOVmGL"></path></svg>
        </ChevronIcon>
        Blog
      </BackLink>
    </BackToBlock>
  )
}

export default BreadCrumbs