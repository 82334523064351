import React, { useContext, useEffect }  from "react";
import { graphql, Link, navigate } from 'gatsby';
import Header from "@common/menus/header"
import styled from 'styled-components'
import { FirebaseContext } from '@components/Firebase'
import Seo from '@components/Seo'
import { GatsbyImage } from "gatsby-plugin-image";
import BackToAppButton from "@common/BackToAppButton"
import BreadCrumbs from "@blog/BreadCrumbs";
import parse from "html-react-parser"

const Section = styled.section`
  background-color: ${props => props.theme.backgroundColor};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top:${props => props.user ? 30 : 80}px;
  >article{
    max-width: 1000px;
    padding:5%;
    >h1{
      font-size:2rem;
      margin:0;
    }
    small{
      font-family: 'Poppins';
      font-size:0.8rem;
    }
  }
`


const CTABannerWrapper = styled.div`
  display:grid;
  place-items:center;
  width:100%;
`;



const BlogPostTemplate = (props) => {
  const {user} = useContext(FirebaseContext);
  const post = props.data.wpPost
  const absoluteImageUrl = post.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src
 
  useEffect(() => {
    // relaod to fix broken layout when entering with direct link
    navigate(`${props.location.pathname}`)
  }, [props.location.pathname])

  return (
    <>
    <Seo 
      title={post.seo.title}
      description={post.seo.metaDesc}
      image={absoluteImageUrl}
    />
    <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
    <Section user={user}>
      <article
        itemScope
        itemType="http://schema.org/Article"
      >
        <BreadCrumbs/>
        <small>{post.date}</small>
        <h1 itemProp="headline">{post.title}</h1>
        <div>{parse(post.content)}</div>
        <BreadCrumbs/>
        {user && <BackToAppButton/> }
        <CTABannerWrapper>
          <Link to={'/register'}>
            <GatsbyImage image={props.data.banner.childImageSharp.gatsbyImageData} alt={`Sign Up Call to action`} />
          </Link>
        </CTABannerWrapper>
      </article>
    </Section>
    </>
  );
}; 

//this way will inject query into props.data
export const query = graphql`
query WPPostQuery($postId: String!){
  wpPost(id: {eq: $postId}) {
    id,
    title,
    content
    isSticky
    title
    content
    slug
    excerpt
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
    }
    seo{
      metaDesc
      title
    }
  }
  banner: file(relativePath: {eq: "blog/sign-up.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED, width: 620, layout: CONSTRAINED)
    }
  }
}
`;  


export default BlogPostTemplate;